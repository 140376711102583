<template>
  <dash-page-new
      no-padding
      no-header
  >

    <template #default>
<!--      :color="wsLIGHTCARD"-->
      <v-sheet  class="fill-height">
        <div class="d-flex fill-height"  >
            <messengerSidebar />

          <div style="width: 100%" >
            <messengerChat
                v-if="['active_chats' , 'manager_chats'].includes(sidebarType)"
            />
            <messengerContactInfo
                v-if="sidebarType === 'contact_book' "  />
          </div>

        </div>
      </v-sheet>
    </template>

    <!-- Messenger-->


  </dash-page-new>

</template>

<script>
import messengerSidebar from "@/modules/messenger/components/UI/messengerSidebar";
import messengerChat from "@/modules/messenger/components/UI/messengerChat";
import messengerContactInfo from "@/modules/messenger/components/UI/messengerContactInfo";
import {mapMutations,mapState} from "vuex";
export default {
  name: "DashMessenger",
  components : {
    messengerSidebar,
    messengerChat,
    messengerContactInfo
  },
  data() {
    return {

    }
  },
  computed : {
    ...mapState('messenger', [
      'sidebarType',
        'selectedChatId'
    ]),
  },
  methods : {
    ...mapMutations('messenger', ['FLUSH_MESSENGER'])
  },
  mounted() {
    this.$store.state.messenger.dashMessengerOpened = true
    if ( !this.sidebarType ) {
      this.$store.state.messenger.sidebarType = 'active_chats'
    }
  },
  beforeDestroy() {
    this.$store.state.messenger.dashMessengerOpened = false
    this.FLUSH_MESSENGER()
  }


}
</script>

<style scoped>

</style>